import React, { useCallback, useContext, useEffect, useState } from 'react';
import Description from './Description';
// import Roadmap from './OldRoadmap/Roadmap';
import MyRoadmap from './MyRoadmap/MyRoadmap';
import RoadmapList from './RoadmapList/RoadmapList';
import { UserContext } from '../../utils/contexts/UserContext';
import { getRoadmaps } from '../../services/roadmapTask/roadmapService';
import { getAccount } from '../../services/auth/authService';

export default function RoadmapMain({ studentUuid }) {
  const { user } = useContext(UserContext);

  const isTeacher = user?.roles?.some((r) => r.name === 'PROJECTADMIN');
  // const [tab, setTab] = useState(state?.tab === 'roadmap' ? 0 : 1);
  const [tab, setTab] = useState(0);
  const [roadmap, setRoadmap] = useState();
  const [userProfile, setUserProfile] = useState();

  const getAccountData = useCallback(() => {
    getAccount(studentUuid).then((res) => {
      setUserProfile(res);
    });
  }, [studentUuid]);

  const getRoadmapData = useCallback(() => {
    getRoadmaps({
      student: studentUuid ?? user?.uuid,
    })
      .then((res) => {
        setRoadmap(res?.results[0]);
      })
      .catch(() => {});
    //   .finally(() => setLoading(false));
  }, [studentUuid, user?.uuid]);

  useEffect(() => {
    getRoadmapData();
  }, [getRoadmapData]);

  useEffect(() => {
    if (isTeacher || studentUuid || roadmap) {
      setTab(0);
    }
  }, [isTeacher, roadmap, studentUuid]);

  useEffect(() => {
    console.log(studentUuid);
    if (studentUuid) {
      getAccountData();
    }
  }, [studentUuid, getAccountData]);

  return (
    <div className='flex flex-col gap-4 p-4 h-full'>
      <Description />
      <div className='flex lg:flex-row flex-col h-full'>
        {/* <div
          className='flex flex-row rounded-2xl border overflow-hidden min-h-[3rem]
                        lg:flex-col lg:w-1/5 lg:h-full lg:py-4'
        >
          {(isTeacher || studentUuid || roadmap) && (
            <div
              className={`flex flex-row border-l-4 items-center gap-1 px-4 p-2 lg:h-auto h-12 lg:text-lg text-sm hover:bg-slate-100 ${
                tab === 0
                  ? 'bg-slate-100 border-current text-primary'
                  : 'border-transparent cursor-pointer'
              }`}
              onClick={() => setTab(0)}
            >
              <MyRoadmapIcon className='fill-current lg:h-6 h-5' />
              {userProfile
                ? `${userProfile.first_name}'s Roadmap`
                : 'My Roadmap'}
            </div>
          )}
          <div
            className={`flex flex-row lg:border-l-4 border-l-[6px] items-center gap-1 px-4 p-2 lg:h-auto h-12 lg:text-lg text-sm hover:bg-slate-100 ${
              tab === 1
                ? 'bg-slate-100 border-current text-primary'
                : 'border-transparent cursor-pointer'
            }`}
            onClick={() => setTab(1)}
          >
            <MyTasksIcon className='fill-current lg:h-6 h-5' />
            {userProfile ? `${userProfile.first_name}'s Tasks` : 'My Tasks'}
          </div>
        </div> */}
        <div className='overflow-scroll w-full'>
          {tab === 0 &&
            (isTeacher && !studentUuid ? (
              <RoadmapList />
            ) : (
              <MyRoadmap roadmap={roadmap} />
            ))}
          {/* {tab === 1 && <Roadmap studentUuid={studentUuid} />} */}
        </div>
      </div>
    </div>
  );
}
