import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import './Timeline.css';

const getDaysFromYearStart = (date) => {
  date = new Date(date);
  let year_start = new Date(`01-01-${new Date().getFullYear()}`);
  let Difference_In_Time = date.getTime() - year_start.getTime();
  return Math.round(Difference_In_Time / (1000 * 3600 * 24));
};

const Timeline = ({ data }) => {
  const [t] = useTranslation();

  const [isOpen, setIsOpen] = useState(new Array(data?.length).fill(false));
  const date = new Date().getFullYear();
  const startDate = date;
  const endDate = date + 1;
  const ref = useRef();
  const refMonth = useRef([]);

  useEffect(() => {
    let firstTask = new Date(
      data.body.reduce(
        (prev, cur) => (prev < cur.start_date ? prev : cur.start_date),
        data.body[0].start_date,
      ),
    );

    if (refMonth.current?.length) {
      const monthElement =
        refMonth.current[
          firstTask.getMonth() +
            (firstTask.getFullYear() === startDate ? 0 : 12)
        ];

      const parentElement = ref.current;
      parentElement.scrollTo({
        left: monthElement.offsetLeft,
        behavior: 'smooth',
      });
    }
  }, [data.body, refMonth, startDate]);

  const MONTHS = {
    Jan: 31,
    Feb: 28,
    Mar: 31,
    Apr: 30,
    May: 31,
    Jun: 30,
    Jul: 31,
    Aug: 31,
    Sep: 30,
    Oct: 31,
    Nov: 30,
    Dec: 31,
  };

  return (
    <div className='flex flex-row rounded-lg shadow-md border'>
      <div className='overflow-auto' ref={ref}>
        <table className='table-auto w-1/2 border-separate border-spacing-0 mt-4 '>
          <thead>
            <tr className='text-gray-500 border-b min-w-fit'>
              <th className='border-b border-r px-2 min-w-[5rem] bg-white sticky left-0'>
                {t('roadmap.type')}
              </th>
              <th className='text-left border-b border-r px-2 bg-white sticky left-20'>
                {t('roadmap.title')}
              </th>
              <th className='border-b px-2 flex flex-row overflow-visible w-full'>
                {[...Array((endDate - startDate + 1) * 12).keys()].map(
                  (month, i) => {
                    let monthKey = Object.keys(MONTHS)[month % 12];
                    return (
                      <div
                        key={`${monthKey} ${
                          Math.floor(month / 12) + startDate
                        }`}
                        ref={(e) => {
                          refMonth.current[month] = e;
                        }}
                        style={{ minWidth: `${MONTHS[monthKey] / 4}rem` }}
                        className='text-center border-r last:border-r-0'
                      >
                        {monthKey} {Math.floor(month / 12) + startDate}
                      </div>
                    );
                  },
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.body?.map((d, index) => {
              return (
                <Fragment key={d.uuid}>
                  <tr
                    key={d.uuid}
                    className='last:border-b-0 hover:bg-gray-100 h-10'
                  >
                    <td className='py-2 border-b border-r bg-white z-[1] px-2 sticky left-0'>
                      <div className='flex flex-row items-center gap-1 justify-center'>
                        {isOpen[index] ? (
                          <ChevronUpIcon
                            className='h-4 cursor-pointer'
                            onClick={() => {
                              setIsOpen((prev) => {
                                let temp = [...prev];
                                temp[index] = false;
                                return temp;
                              });
                            }}
                          />
                        ) : (
                          <ChevronDownIcon
                            className='h-4 cursor-pointer'
                            onClick={() => {
                              setIsOpen((prev) => {
                                let temp = [...prev];
                                temp[index] = true;
                                return temp;
                              });
                            }}
                          />
                        )}
                        <CalendarIcon className='h-5 text-success' />
                      </div>
                    </td>
                    <td
                      className='lg:text-base text-sm border-b border-r min-w-[20rem] max-w-[20rem] w-80 bg-white z-[1] px-2 sticky left-20'
                      title={d.name}
                    >
                      {d.name}
                    </td>
                    <td className='border-b relative overflow-hidden'>
                      <div
                        style={{
                          left: `${getDaysFromYearStart(d.start_date) / 4}rem`,
                          width: `${
                            (getDaysFromYearStart(
                              new Date(d.start_date).getTime() +
                                24 *
                                  60 *
                                  60 *
                                  1000 *
                                  Number(
                                    d.time
                                      ?.replace('day', '')
                                      ?.replace('s', ''),
                                  ),
                            ) -
                              getDaysFromYearStart(d.start_date)) /
                            4
                          }rem`,
                        }}
                        className={`h-5 rounded-lg bg-success absolute transform -translate-y-2/4`}
                      />
                    </td>
                  </tr>
                  {isOpen[index] &&
                    d?.subtasks?.map((c) => (
                      <tr className={` hover:bg-gray-100`} key={c?.uuid}>
                        <td className='py-2 border-b border-r px-2 bg-white z-[1] h-10 sticky left-0 '>
                          <div className='flex flex-row items-center gap-1 justify-center'>
                            <ChevronDownIcon className='h-4 invisible' />

                            <CalendarIcon className='h-5 text-primary' />
                          </div>
                        </td>
                        <td
                          className='lg:text-base text-sm border-b border-r px-2 bg-white z-[1] max-w-[20rem] h-10 sticky left-20'
                          title={c.name}
                        >
                          {c.name}
                        </td>
                        <td className='border-b relative h-10 break-all overflow-hidden'>
                          <div
                            style={{
                              left: `${
                                getDaysFromYearStart(c.start_date) / 4
                              }rem`,
                              width: `${
                                (getDaysFromYearStart(
                                  new Date(c.start_date).getTime() +
                                    24 *
                                      60 *
                                      60 *
                                      1000 *
                                      Number(
                                        c.time
                                          ?.replace('day', '')
                                          ?.replace('s', ''),
                                      ),
                                ) -
                                  getDaysFromYearStart(c.start_date)) /
                                4
                              }rem`,
                            }}
                            className={`h-5 rounded-lg bg-primary absolute transform -translate-y-2/4`}
                          />
                        </td>
                      </tr>
                    ))}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Timeline;
