import React, { useEffect, useState } from 'react';
import SurveyPart from './SurveyPart';
import EditPart from './EditPart/EditPart';
import { Tab } from '@headlessui/react';
import ApprovePart from './ApprovePart';
import SelectStudentPart from './SelectStudentPart';
import { useLocation } from 'react-router-dom';

export default function CreateRoadmap() {
  // const { user } = useContext(UserContext);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedStudentUuid, setSelectedStudentUuid] = useState(null);
  const [comment, setComment] = useState('');
  const [userSurveyResponse, setUserSurveyResponse] = useState([]);
  const [draftRoadmapUuid, setDraftRoadmapUuid] = useState(null);
  const [draftRoadmap, setDraftRoadmap] = useState(null);
  const [draftRoadmapName, setDraftRoadmapName] = useState('');
  const [timePeriod, setTimePeriod] = useState('');
  const [isManually, setIsManually] = useState(false);
  let { state } = useLocation();

  useEffect(() => {
    if (state?.uuid) {
      setDraftRoadmapUuid(state.uuid);
      setSelectedIndex(2);
    }
    if (state?.student) {
      setSelectedStudent(state?.student);
      setSelectedStudentUuid(state?.student.uuid);
    }
    // Устанавливаем фиксированный мета-тег для десктопного отображения
    const metaViewport = document.querySelector('meta[name="viewport"]');
    metaViewport.setAttribute('content', 'width=1200');

    // Возвращаем мета-тег к стандартному состоянию при размонтировании компонента
    return () => {
      metaViewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0',
      );
    };
  }, []);

  const steps = [
    {
      number: 1,
      name: "Student's information",
      panel: (
        <SelectStudentPart
          setDraftRoadmapUuid={setDraftRoadmapUuid}
          selectedStudent={selectedStudent}
          setSelectedStudent={setSelectedStudent}
          selectedStudentUuid={selectedStudentUuid}
          setSelectedStudentUuid={setSelectedStudentUuid}
          setSelectedIndex={setSelectedIndex}
          comment={comment}
          setIsManually={setIsManually}
          setComment={setComment}
        />
      ),
    },
    {
      number: 2,
      name: 'Survey',
      panel: (
        <SurveyPart
          setSelectedIndex={setSelectedIndex}
          comment={comment}
          selectedStudent={selectedStudent}
          userSurveyResponse={userSurveyResponse}
          setUserSurveyResponse={setUserSurveyResponse}
          setDraftRoadmapUuid={setDraftRoadmapUuid}
          setTimePeriod={setTimePeriod}
          timePeriod={timePeriod}
        />
      ),
    },
    {
      number: 3,
      name: 'Edit',
      panel: (
        <EditPart
          setSelectedIndex={setSelectedIndex}
          draftRoadmapUuid={draftRoadmapUuid}
          draftRoadmap={draftRoadmap}
          setDraftRoadmap={setDraftRoadmap}
          setDraftRoadmapName={setDraftRoadmapName}
          isManually={isManually}
          studentId={selectedStudentUuid}
        />
      ),
    },
    {
      number: 4,
      name: 'Approve',
      panel: (
        <ApprovePart
          setSelectedIndex={setSelectedIndex}
          data={draftRoadmap}
          draftRoadmapUuid={draftRoadmapUuid}
          student={selectedStudent}
          userSurveyResponse={userSurveyResponse}
          draftRoadmapName={draftRoadmapName}
          isManually={isManually}
          timePeriod={timePeriod}
        />
      ),
    },
  ];

  return (
    <div>
      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={(index) => {
          return index;
          // if (maxIndex >= index) {
          //   setSelectedIndex(index);
          // }
        }}
      >
        <Tab.List className='flex flex-row justify-center gap-32 my-8'>
          {steps.map((step, index) => (
            <div key={step.number} className='flex items-start'>
              <Tab>
                <div className='relative flex flex-col items-center'>
                  <div
                    className={`w-12 h-12 rounded-full ${
                      selectedIndex >= index ? 'bg-blue-500' : 'bg-gray-300'
                    } text-white flex items-center justify-center text-lg z-10`}
                  >
                    {step.number}
                  </div>
                  <p className='mt-2 text-center w-12'>{step.name}</p>
                  {index !== steps.length - 1 && (
                    <div className='absolute top-6 -right-36 w-40 h-[0.125rem] bg-gray-300' />
                  )}
                </div>
              </Tab>
            </div>
          ))}
        </Tab.List>
        <Tab.Panels>
          {steps.map((step, index) => (
            <Tab.Panel unmount={false} className='' key={index}>
              {step.panel}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
