import {
  ArrowUpIcon,
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon,
  LanguageIcon,
  PencilSquareIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postRoadmapUpdate } from '../../../../services/roadmapTask/roadmapCreateService';
import { Button } from '../../../../components/forms/Buttons/Button';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';
import { toast } from 'react-toastify';
import ViewTask from './BREAD/ViewTask';
import { format } from 'date-fns';
import Add from './BREAD/Add';
import AddSub from './BREAD/AddSub';
import Edit from './BREAD/Edit';
import EditSub from './BREAD/EditSub';
import Timeline from './Timeline';
import { useNavigate } from 'react-router-dom';

export default function EditPart({
  draftRoadmapUuid,
  setSelectedIndex,
  draftRoadmap,
  setDraftRoadmap,
  setDraftRoadmapName,
  isManually,
}) {
  const [roadmapLoading, setRoadmapLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveDraftLoading, setSaveDraftLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let intervalId;

    const checkCondition = async () => {
      if (draftRoadmapUuid) {
        try {
          const body = {
            draft_roadmap_uuid: draftRoadmapUuid,
            action: 'UPDATE',
            user_response: '',
            roadmap_body: [],
          };
          setRoadmapLoading(true);
          const response = await postRoadmapUpdate(body);
          const responseData = response;

          // Check if the attribute equals the desired value
          if (
            responseData?.draft_status === 1
            // ||
            // responseData?.draft_status === 3
          ) {
            setDraftRoadmapName(responseData.roadmap_name);

            setRoadmapLoading(false);
            // If condition is met, update the state and clear the interval
            setDraftRoadmap(responseData);
            clearInterval(intervalId);
          } else {
            setRoadmapLoading(true);

            // If condition is not met, update the state with the latest data
            setDraftRoadmap(responseData);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
    if (!isManually) {
      intervalId = setInterval(checkCondition, 2000);
    } else {
      setDraftRoadmap({
        body: [],
        draft_status: 1,
        response: '',
        roadmap_name: '',
        draft_roadmap_uuid: draftRoadmapUuid,
      });
    }
    // Set up the interval to call the function every 10 seconds
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [draftRoadmapUuid]);

  return (
    <div className='flex flex-col gap-2 md:h-[calc(100vh-24rem)]'>
      {!draftRoadmap?.draft_roadmap_uuid ? (
        <div className='flex flex-col w-full h-full items-center justify-center'>
          <SimpleLoader className='h-5' />
          <span>Roadmap is being created</span>
        </div>
      ) : (
        <>
          <div className='flex flex-row gap-8'>
            <div className='min-w-fit'>
              <Chat
                loading={roadmapLoading}
                setLoading={setRoadmapLoading}
                draftRoadmapUuid={draftRoadmapUuid}
                draftRoadmap={draftRoadmap}
                setDraftRoadmap={setDraftRoadmap}
                setDraftRoadmapName={setDraftRoadmapName}
                disabled={
                  !draftRoadmap?.body?.length ||
                  !draftRoadmap?.body?.some((t) => !!t.subtasks?.length)
                }
              />
            </div>
            {roadmapLoading ? (
              <div className='relative w-full'>
                <SimpleLoader className='h-6' />
              </div>
            ) : (
              <div className='w-full'>
                <Table data={draftRoadmap} setData={setDraftRoadmap} />
                <div className='my-8' />
                {!!draftRoadmap?.body?.length && (
                  <Timeline data={draftRoadmap} />
                )}
              </div>
            )}
          </div>
          <div className='flex flex-row justify-end px-6 pb-8 gap-2'>
            <Button
              disabled={saveDraftLoading}
              loading={saveDraftLoading}
              onClick={() => {
                setSaveDraftLoading(true);
                const body = {
                  draft_roadmap_uuid: draftRoadmapUuid,
                  roadmap_body: draftRoadmap.body,
                  action: 'SAVE_DT',
                };
                postRoadmapUpdate(body)
                  .then((res) => {
                    setDraftRoadmap(res);
                    toast.success('Your changes have been saved as a draft');
                  })
                  .catch((e) => {
                    toast.error('Something went wrong');
                  })
                  ?.finally(() => setSaveDraftLoading(false));
              }}
              text='Save as Draft'
              color='success'
            />
            <Button
              disabled={saveLoading}
              loading={saveLoading}
              onClick={() => {
                setSaveLoading(true);
                const body = {
                  draft_roadmap_uuid: draftRoadmapUuid,
                  roadmap_body: draftRoadmap.body,
                  action: 'SAVE_DT',
                };

                postRoadmapUpdate(body)
                  .then((res) => {
                    navigate(`/roadmap`);
                  })
                  .catch((e) => {
                    toast.error('Something went wrong');
                  })
                  ?.finally(() => setSaveLoading(false));
              }}
              text='Exit to Roadmap'
              color='error'
            />
            <Button
              disabled={roadmapLoading}
              loading={roadmapLoading}
              onClick={async () => {
                // const body = {
                //   draft_roadmap_uuid: draftRoadmapUuid,
                //   // user_response: input,
                //   roadmap_body: draftRoadmap,
                //   action: 'SAVE',
                // };
                // await postRoadmapUpdate(body).then((res) => {
                //   setDraftRoadmap(res);
                // });
                setSelectedIndex((v) => v + 1);
              }}
              text='Next'
            />
          </div>
        </>
      )}
    </div>
  );
}

const Chat = ({
  draftRoadmapUuid,
  draftRoadmap,
  setDraftRoadmap,
  setLoading,
  setDraftRoadmapName,
  loading,
  disabled,
}) => {
  // State to hold chat messages and input value
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  // Function to handle message submission
  const handleSendMessage = async (userInput) => {
    if (userInput.trim()) {
      setMessages((prev) => [...prev, { type: 'user', text: userInput }]);
      setInput('');

      const body = {
        draft_roadmap_uuid: draftRoadmapUuid,
        user_response: userInput,
        roadmap_body: draftRoadmap?.body,
        action: 'UPDATE',
      };
      setLoading(true);
      await postRoadmapUpdate(body)
        .then((res) => {
          setDraftRoadmapName(res.roadmap_name);
          if (res.draft_status === 3) {
            let newMessages;
            setMessages((prev) => {
              newMessages = prev;
              newMessages[newMessages?.length - 1] = {
                type: 'userError',
                text: newMessages[newMessages?.length - 1]?.text,
              };
              return newMessages;
            });
            toast.error('AI response error, please try again');
            return;
          }
          if (res.response) {
            setMessages((prev) => [
              ...prev,
              { type: 'bot', text: res.response },
            ]);
          }
          setDraftRoadmap(res);
        })
        ?.finally(() => setLoading(false));
    }
  };

  // Function to handle advice selection
  const handleAdvice = (advice) => {
    handleSendMessage(advice);
    // setMessages([...messages, { type: 'bot', text: advice }]);
  };

  return (
    <div className='max-w-md p-4 bg-white border rounded-md shadow-lg'>
      {/* Chat Messages */}

      <div className='flex flex-col h-96 overflow-y-auto mb-4'>
        {!messages?.length && (
          <div className='flex flex-col items-center'>
            <SparklesIcon className='h-16 mt-8 bg-gray-200 stroke-1 p-4 rounded-full' />
            <span className='text-primary text-xl ont-semibold'>
              Roadmap AI
            </span>
          </div>
        )}
        {messages?.map((message, index) => (
          <>
            <div
              key={index}
              className={`p-2 rounded ${
                message.type === 'user'
                  ? 'bg-blue-100 text-blue-800 self-end w-fit max-w-[70%]'
                  : message.type === 'userError'
                  ? 'bg-red-100 text-error self-end w-fit max-w-[70%] flex flex-col mb-0'
                  : 'bg-gray-100 text-gray-800 w-[70%] max-w-fit'
              } mb-2`}
            >
              {message.text}
            </div>
            {message.type === 'userError' && (
              <span className='self-end flex flex-row gap-1 items-center text-error mb-2'>
                Please try again <ExclamationTriangleIcon className='h-5' />
              </span>
            )}
          </>
        ))}
      </div>
      {disabled ? (
        <span className='text-sm text-error animate-pulse'>
          To use Roadmap AI, add at least one task and one subtask.
        </span>
      ) : (
        <div className='mb-4 flex flex-row w-full gap-4'>
          {/* Advice Buttons */}
          <button
            disabled={loading}
            onClick={() =>
              handleAdvice('Rewrite the first task to make it more challenging')
            }
            className='p-2 text-xs flex flex-row bg-gray-300 rounded-lg mr-2 hover:bg-blue-600 w-1/2'
          >
            <PencilSquareIcon className='h-8' />
            Rewrite the first task to make it more challenging
          </button>
          <button
            disabled={loading}
            onClick={() =>
              handleAdvice('Include a task about learning a new language')
            }
            className='p-2 text-xs flex flex-row
           bg-gray-300 rounded-lg hover:bg-green-600 w-1/2'
          >
            <LanguageIcon className='h-8' />
            Include a task about learning a new language
          </button>
        </div>
      )}
      {/* Input and Enter Button */}
      <div className='relative flex'>
        <input
          type='text'
          value={input}
          disabled={disabled}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (loading) return;
            if (e.key === 'Enter') handleSendMessage();
          }}
          placeholder='Message Roadmap AI'
          className='flex-grow p-[0.625rem] pl-4 border border-gray-300 rounded-full text-sm font-semibold focus:outline-none'
        />

        {loading ? (
          <div className='absolute right-2 top-1/2 transform -translate-y-1/2 p-[0.375rem]'>
            <SimpleLoader className='h-4' />
          </div>
        ) : (
          <button
            disabled={disabled}
            onClick={() => handleAdvice(input)}
            className='absolute right-2 top-1/2 transform -translate-y-1/2 p-[0.375rem] bg-gray-300 text-white rounded-full hover:bg-blue-600'
          >
            <ArrowUpIcon className='h-4 w-4' />
          </button>
        )}
      </div>
      <p className='flex flex-col text-sm text-gray-400 mt-2'>
        <span>* Changing tasks using AI may take a little time</span>
        <span>
          * To get the best results, make sure your prompts are detailed and
          clear
        </span>
      </p>
    </div>
  );
};

const Table = ({ data, setData }) => {
  const [t] = useTranslation();

  const [isOpen, setIsOpen] = useState(null);

  useEffect(() => {
    if (!isEmpty(data?.body)) {
      setIsOpen(new Map(data?.body?.map((d) => [d?.name, false])));
    }

    return () => {};
  }, [data]);

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  return (
    <>
      <Add data={data} setData={setData} />
      <div className='rounded-lg shadow-md border pt-2'>
        <table className='table-auto w-full'>
          <thead>
            <tr className='text-gray-500 border-b'>
              <th className='border-r px-2'>{t('roadmap.type')}</th>
              <th className='text-left border-r px-2'>{t('roadmap.title')}</th>
              <th className='border-r px-2'>{t('Duration')}</th>
              <th className='border-r px-2'>{t('Start Date')}</th>
              <th className='px-2'></th>
            </tr>
          </thead>
          <tbody>
            {isEmpty(data?.body) === false &&
              data?.body?.map((d, index) => {
                const start_date = data?.body
                  ?.slice(0, index)
                  .reduce((prev, cur) => {
                    const daysToAdd =
                      Number(cur?.time?.replace('day', '')?.replace('s', '')) ||
                      0;
                    prev?.setDate(prev?.getDate() + daysToAdd);
                    return prev;
                  }, new Date());

                const end_date = new Date(start_date);
                const currentDaysToAdd =
                  Number(d.time?.replace('day', '')?.replace('s', '')) || 0;
                end_date.setDate(start_date.getDate() + currentDaysToAdd);

                return (
                  <Fragment key={index}>
                    <tr className='border-b last:border-b-0 hover:bg-gray-100 h-10'>
                      <td className='py-2 border-r px-2'>
                        <div className='flex flex-row items-center gap-1 justify-center'>
                          {isOpen?.get(d?.name) ? (
                            <ChevronUpIcon
                              className='h-4 cursor-pointer'
                              onClick={() => {
                                setIsOpen((prev) => {
                                  let temp = new Map(prev);
                                  temp.set(d?.name, false);
                                  return temp;
                                });
                              }}
                            />
                          ) : (
                            <ChevronDownIcon
                              className='h-4 cursor-pointer'
                              onClick={() => {
                                setIsOpen((prev) => {
                                  let temp = new Map(prev);
                                  temp.set(d?.name, true);
                                  return temp;
                                });
                              }}
                            />
                          )}
                          <CalendarIcon className='h-5 text-success' />
                          <SparklesIcon className='h-5 invisible' />
                        </div>
                      </td>
                      <td className='lg:text-base pl-2 text-sm border-r min-w-[20rem] max-w-[20rem] w-80'>
                        {d?.name}
                      </td>
                      <td className='font-light lg:text-base text-sm text-center border-r px-2'>
                        {d?.time}
                      </td>
                      <td className='font-light lg:text-base text-sm text-center border-r px-2'>
                        {d?.start_date}
                      </td>
                      <td
                        className={`flex flex-row gap-2 lg:text-base text-xs py-2 px-2`}
                      >
                        <ViewTask
                          task={{
                            name: d?.name,
                            description: d?.description,
                            deadline: end_date
                              ? format(end_date, 'dd-MM-yyyy')
                              : '',
                          }}
                        />
                        <Edit
                          data={data}
                          setData={setData}
                          task={d}
                          index={index}
                        />
                        <Button
                          color='error-solid'
                          text='Delete'
                          className='max-h-6'
                          onClick={() => {
                            // const newArray = data?.body?.splice(index, 1);

                            const newArray = data?.body?.filter(function (obj) {
                              return obj.name !== d?.name;
                            });

                            const newRoadmap = { ...data, body: newArray };
                            setData(newRoadmap);
                          }}
                        />
                      </td>
                    </tr>
                    {isOpen?.get(d?.name) &&
                      d?.subtasks?.map((c, i) => {
                        const sub_start_date = d?.subtasks
                          ?.slice(0, i)
                          .reduce((prev, cur) => {
                            const daysToAdd =
                              Number(
                                cur?.time?.replace('day', '')?.replace('s', ''),
                              ) || 0;
                            prev?.setDate(prev?.getDate() + daysToAdd);
                            return prev;
                          }, new Date(start_date));

                        const sub_end_date = new Date(sub_start_date);
                        const currentDaysToAdd =
                          Number(
                            c.time?.replace('day', '')?.replace('s', ''),
                          ) || 0;
                        sub_end_date.setDate(
                          sub_start_date.getDate() + currentDaysToAdd,
                        );

                        return (
                          <tr
                            className='border-b last:border-b-0 hover:bg-gray-100 h-10'
                            key={c?.name}
                          >
                            <td className='py-2 border-r px-2'>
                              <div className='flex flex-row items-center gap-1 justify-center'>
                                <ChevronDownIcon className='h-4 invisible' />

                                <CalendarIcon className='h-5 text-primary' />
                                <SparklesIcon className='h-5 invisible' />
                              </div>
                            </td>
                            <td
                              className='lg:text-base text-sm border-r px-2 min-w-[16rem] max-w-[16rem] w-64
                      '
                            >
                              {c?.name}
                            </td>
                            <td className='font-light lg:text-base text-sm text-center border-r px-2'>
                              {c?.time}
                            </td>
                            <td className='font-light lg:text-base text-sm text-center border-r px-2'>
                              {c?.start_date}
                            </td>
                            <td
                              className={`flex flex-row gap-2 lg:text-base text-xs py-2 px-2`}
                            >
                              <ViewTask
                                task={{
                                  name: c?.name,
                                  description: c?.description,
                                  deadline: sub_end_date
                                    ? format(sub_end_date, 'dd-MM-yyyy')
                                    : '',
                                }}
                              />
                              <EditSub
                                data={data}
                                setData={setData}
                                task={d}
                                subtask={c}
                                taskIndex={index}
                              />
                              <Button
                                type='button'
                                color='error-solid'
                                text='Delete'
                                className='max-h-6'
                                onClick={() => {
                                  const newSubArray = d?.subtasks?.filter(
                                    function (obj) {
                                      return obj.name !== c?.name;
                                    },
                                  );

                                  let minStartDate = newSubArray.reduce(
                                    (prev, cur) =>
                                      prev < cur.start_date
                                        ? prev
                                        : cur.start_date,
                                    newSubArray[0]?.start_date,
                                  );

                                  const newArray = data?.body?.map((item) => {
                                    if (item?.name === d?.name) {
                                      let newTime = Math.floor(
                                        (newSubArray.reduce((prev, next) => {
                                          let next_deadline =
                                            new Date(
                                              next.start_date,
                                            ).getTime() +
                                            Number(
                                              next?.time
                                                ?.replace('day', '')
                                                ?.replace('s', ''),
                                            ) *
                                              24 *
                                              60 *
                                              60 *
                                              1000;
                                          return prev > next_deadline
                                            ? prev
                                            : next_deadline;
                                        }, 0) -
                                          new Date(minStartDate)) /
                                          (24 * 60 * 60 * 1000),
                                      );
                                      return {
                                        ...item,
                                        time: `${newTime} day${
                                          newTime > 1 ? 's' : ''
                                        }`,
                                        subtasks: newSubArray,
                                      };
                                    } else {
                                      return item;
                                    }
                                  });

                                  const newRoadmap = {
                                    ...data,
                                    body: newArray,
                                  };
                                  setData(newRoadmap);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    {d?.status !== 'finished' && isOpen?.get(d?.name) && (
                      <tr
                        className='border-b last:border-b-0 hover:bg-gray-100 h-10'
                        key={index}
                      >
                        <td className='py-2 border-r px-2'>
                          <div className='flex flex-row items-center gap-1 justify-center'>
                            <ChevronUpIcon className='h-4 invisible' />
                            <AddSub
                              data={data}
                              setData={setData}
                              name={d?.name}
                            />
                            <SparklesIcon className='h-5 invisible' />
                          </div>
                        </td>
                        <td className='lg:text-bas text-sm border-r px-2'></td>
                        <td className='font-light lg:text-base text-sm text-center border-r px-2'></td>
                        <td
                          className={`lg:text-base text-sm pl-1 pr-2 border-r px-2`}
                        ></td>
                        {/* <td className='text-center border-r px-2'></td> */}
                      </tr>
                    )}
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};
