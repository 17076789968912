import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { Button } from '../../../components/forms/Buttons/Button';
import { postRoadmapUpdate } from '../../../services/roadmapTask/roadmapCreateService';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function ApprovePart({
  data,
  draftRoadmapUuid,
  student,
  setSelectedIndex,
  draftRoadmapName,
  timePeriod,
  isManually,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(null);
  const [tasks, setTasks] = useState([]);
  const { state } = useLocation();

  useEffect(() => {
    // console.log(data, userSurveyResponse, student);
    if (!isEmpty(data?.body)) {
      setIsOpen(new Map(data?.body?.map((d) => [d?.name, false])));
      setTasks(data?.body);
    }

    return () => {};
  }, [data]);

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  return (
    <div className='flex flex-row justify-center'>
      <div className='flex flex-col w-2/3'>
        <div className='flex flex-col mb-4 font-bold'>
          <div className='flex flex-row gap-2'>
            <span>Student's name:</span>
            <span className='font-normal'>
              {student?.first_name} {student?.last_name}
            </span>
          </div>
          {!isManually && !state?.uuid && (
            <div className='flex flex-row gap-2'>
              <span>Name of the roadmap:</span>
              <span className='font-normal'>{draftRoadmapName}</span>
            </div>
          )}
          <div className='flex flex-row gap-2'>
            <span>Time frame of the roadmap:</span>
            <span className='font-normal'>{timePeriod} months</span>
          </div>
        </div>
        <div className='rounded-lg w-full shadow-md border pt-2'>
          <table className='table-auto w-full'>
            <thead>
              <tr className='text-gray-500 border-b'>
                {/* <th className='border-r px-2'>{}</th> */}
                <th className='border-r px-2'>{t('roadmap.type')}</th>
                <th className='text-left border-r px-2'>
                  {t('roadmap.title')}
                </th>
                <th className='border-r px-2'>{t('Duration')}</th>
                <th className='border-r px-2'>{t('Start date')}</th>
                <th className='border-r px-2'>{t('End date')}</th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(tasks) === false &&
                tasks?.map((d, index) => {
                  const end_date = new Date(d.start_date);
                  const currentDaysToAdd =
                    Number(d.time?.replace('day', '')?.replace('s', '')) || 0;
                  end_date.setDate(
                    new Date(d.start_date).getDate() + currentDaysToAdd,
                  );

                  return (
                    <Fragment key={index}>
                      <tr className='border-b last:border-b-0 hover:bg-gray-100 h-10'>
                        {/* <td className='flex flex-row justify-center py-2 border-r px-2'>
                          <div className=''>
                            <ChevronUpIcon
                              className={`h-5 cursor-pointer ${
                                !index && 'invisible'
                              }`}
                              onClick={() => {
                                setTasks(drugReorder(tasks, index, index - 1));
                              }}
                            />
                            <ChevronDownIcon
                              className={`h-5 cursor-pointer ${
                                index + 1 >= tasks?.length && 'invisible'
                              }`}
                              onClick={() => {
                                setTasks(drugReorder(tasks, index, index + 1));
                              }}
                            />
                          </div>
                        </td> */}
                        <td className='py-2 border-r px-2'>
                          <div className='flex flex-row items-center gap-1 justify-center'>
                            {isOpen?.get(d?.name) ? (
                              <ChevronUpIcon
                                className='h-4 cursor-pointer'
                                onClick={() => {
                                  setIsOpen((prev) => {
                                    let temp = new Map(prev);
                                    temp.set(d?.name, false);
                                    return temp;
                                  });
                                }}
                              />
                            ) : (
                              <ChevronDownIcon
                                className='h-4 cursor-pointer'
                                onClick={() => {
                                  setIsOpen((prev) => {
                                    let temp = new Map(prev);
                                    temp.set(d?.name, true);
                                    return temp;
                                  });
                                }}
                              />
                            )}
                            <CalendarIcon className='h-5 text-success' />
                          </div>
                        </td>
                        <td className='lg:text-base pl-2 text-sm border-r min-w-[20rem] max-w-[20rem] w-80'>
                          {d?.name}
                        </td>
                        <td className='font-light lg:text-base text-sm text-center border-r px-2'>
                          {d?.time}
                        </td>
                        <td className='font-light lg:text-base text-sm text-center border-r px-2'>
                          {d.start_date}
                        </td>
                        <td className='font-light lg:text-base text-sm text-center border-r px-2'>
                          {end_date ? format(end_date, 'dd-MM-yyyy') : ''}
                        </td>
                      </tr>
                      {isOpen?.get(d?.name) &&
                        d?.subtasks?.map((c, i) => {
                          // Рассчитываем sub_end_date на основе sub_start_date
                          const sub_end_date = new Date(c.start_date);
                          const currentDaysToAdd =
                            Number(
                              c.time?.replace('day', '')?.replace('s', ''),
                            ) || 0;
                          sub_end_date.setDate(
                            new Date(c.start_date).getDate() + currentDaysToAdd,
                          );

                          // console.log(sub_start_date, sub_end_date, c.time);
                          return (
                            <tr
                              className='border-b last:border-b-0 hover:bg-gray-100 h-10'
                              key={c?.name}
                            >
                              {/* <td className='py-2 border-r px-2'></td> */}
                              <td className='py-2 border-r px-2'>
                                <div className='flex flex-row items-center gap-1 justify-center'>
                                  <ChevronDownIcon className='h-4 invisible' />

                                  <CalendarIcon className='h-5 text-primary' />
                                  {/* <SparklesIcon className='h-5 invisible' /> */}
                                </div>
                              </td>
                              <td
                                className='lg:text-base text-sm border-r px-2 min-w-[16rem] max-w-[16rem] w-64
                      '
                              >
                                {c?.name}
                              </td>
                              <td className='font-light lg:text-base text-sm text-center border-r px-2'>
                                {c?.time}
                              </td>
                              <td className='font-light lg:text-base text-sm text-center border-r px-2'>
                                {c.start_date}
                              </td>
                              <td className='font-light lg:text-base text-sm text-center border-r px-2'>
                                {sub_end_date
                                  ? format(sub_end_date, 'dd-MM-yyyy')
                                  : ''}
                              </td>
                            </tr>
                          );
                        })}
                      {/* {d?.status !== 'finished' && isOpen?.get(d?.name) && (
                      <tr
                        className='border-b last:border-b-0 hover:bg-gray-100 h-10'
                        key={index}
                      >
                        <td className='py-2 border-r px-2'>
                          <div className='flex flex-row items-center gap-1 justify-center'>
                            <ChevronUpIcon className='h-4 invisible' />
                            <SparklesIcon className='h-5 invisible' />
                          </div>
                        </td>
                        <td className='lg:text-bas text-sm border-r px-2'></td>
                        <td className='font-light lg:text-base text-sm text-center border-r px-2'></td>
                        <td
                          className={`lg:text-base text-sm pl-1 pr-2 border-r px-2`}
                        ></td>
                      </tr>
                    )} */}
                    </Fragment>
                  );
                })}
            </tbody>
          </table>
          <div className='flex flex-row justify-between px-6 pb-8 mt-2'>
            <Button
              // disabled={!selectedStudentUuid}
              onClick={() => {
                setSelectedIndex(2);
              }}
              text='Back to Edit'
            />
            <Button
              // disabled={!selectedStudentUuid}
              onClick={async () => {
                const body = {
                  draft_roadmap_uuid: draftRoadmapUuid,
                  // user_response: input,
                  roadmap_body: tasks,
                  action: 'SAVE',
                };
                await postRoadmapUpdate(body)
                  .then((res) => {
                    // setDraftRoadmap(res);
                    console.log('res', res);
                    navigate('/roadmap');
                  })
                  ?.catch((e) => {
                    if (e?.response?.data?.draft_status === 3) {
                      toast.error(e?.response?.data?.error_msg);
                    }
                  })
                  .finally(() => {});
              }}
              text='Next'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
